


// TODO: is this still being used? And if so, how? Maybe some documentation?

jQuery(function($) {
  var ProjectName = {
    html: '',
    myEventItem: '',
    hasSubmenu: '',
    navItem: '',
    titleBar: '',
    header: '',
    init: function() {
      this.win = $(window);
      this.html = $('body');
      this.myEventItem = $('.class');
      //this.hasSubmenu = $('.has-submenu');
      //this.navItem = $('.nav-item');
      this.titleBar = $('.title-bar');
      this.header = $('.header');
      this._bindEvent();
      this._waypoints();
      this._animationSlideToTop();
    },
    _bindEvent: function() {
      ProjectName.myEventItem.on('click', function(e) {
        ProjectName._myEvent(e);
      });
      // ProjectName.hasSubmenu.on('click', function(e){
      //     ProjectName._menuSubMobile(e, $(this));
      // });
      // ProjectName.navItem.on('mouseenter click', function (e) {
      //     ProjectName._menuSubDesktop(e, $(this));
      // });
      ProjectName.titleBar.on('toggled.zf.responsiveToggle', function(e) {
        ProjectName._titleBar(e);
      });
    },
    _myEvent: function(e) {
      e.stopPropagation();
      return false;
    },
    _menuSubMobile: function(e, elThis) {
      // mobile submenu
      // open/close submenu on click parrent item
      e.stopPropagation();
      var openSubMenuClass = 'open';
      var currentItem = elThis.children('.sub-menu');

      if (currentItem.hasClass(openSubMenuClass)) {
        currentItem.removeClass(openSubMenuClass);
      } else {
        $('.nav-drop').removeClass(openSubMenuClass);
        currentItem.addClass(openSubMenuClass);
      }
    },
    _menuSubDesktop: function(e, elThis) {
      // desktop submenu
      // header hook is for .is-header-transparent
      // open/close submenu on hover or click parrent item
      var openSubMenuClass = 'open';
      var activeSubMenuClass = 'active';
      // parrent item open class add
      $('.has-submenu').removeClass(openSubMenuClass);
      // header hook
      ProjectName.header.removeClass(openSubMenuClass);

      if (elThis.hasClass('has-submenu')) {
        elThis.addClass(openSubMenuClass);
        // header hook
        ProjectName.header.addClass(openSubMenuClass);
      }
      // get submenu index
      var index = elThis.data('menuindex');
      if (index) {
        // open submenu area
        $('.nav-drop__body').addClass(openSubMenuClass);
        // submenu open clear
        $('.nav-drop').removeClass(activeSubMenuClass);
        // submenu open
        $('.nav-drop[data-childrenindex="' + index + '"]').addClass(
          activeSubMenuClass
        );
        // current submenu close
        $('.nav-drop.active').on('mouseleave', function() {
          // submenu close
          $(this).removeClass('active');
          // close submenu area
          $('.nav-drop__body').removeClass(openSubMenuClass);
          // parrent item open class remove
          $('.has-submenu').removeClass(openSubMenuClass);
          // header hook
          ProjectName.header.removeClass(openSubMenuClass);
        });
      } else {
        // submenu close
        elThis.removeClass('active');
        // close submenu area
        $('.nav-drop__body').removeClass(openSubMenuClass);
        // parrent item open class remove
        $('.has-submenu').removeClass(openSubMenuClass);
      }
    },
    _titleBar: function(e) {
      e.stopPropagation();
      $('.title-bar button').attr('aria-expanded', function(i, attr) {
        return attr == 'true' ? 'false' : 'true';
      });

      if (ProjectName.html.hasClass('navigation-open')) {
        ProjectName.html.removeClass('navigation-open');
      } else {
        ProjectName.html.addClass('navigation-open');
      }
    },
    _waypoints: function() {
      var waypoints = $('.waypoint-header').waypoint({
        handler: function(direction) {
          if (ProjectName.html.hasClass('is-header-transparent')) {
            ProjectName.html.toggleClass('header--transparent');
          }

          if (ProjectName.html.hasClass('is-header-top')) {
            ProjectName.html.toggleClass('header--scroll');
          }
        }
        //offset: 'bottom-in-view'
      });
    },
    _animationSlideToTop: function() {
      $('.item-animate--slideInUp').css('opacity', 0);

      var waypoints = $('.item-animate--slideInUp').waypoint({
        handler: function(direction) {
          $(this.element).addClass('fadeInUp');
        },
        offset: '100%'
      });
    }
  };

  $(document).ready(function() {
    ProjectName.init();

    // sidebar fix
    if (!Foundation.MediaQuery.atLeast('large')) {
      $('.sticky').foundation('_destroy');
    }
  });
});


jQuery(document).ready(function ($) {

  $('#category-select').change(function(e) {
      this.form.submit();
  });
})
